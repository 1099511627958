var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [_c("page-snippet", { attrs: { name: "support" } })],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { md: "6", cols: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Frequently Asked Questions" } },
                [_c("page-snippet", { attrs: { name: "faq" } })],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Known issues" } },
                [_c("page-snippet", { attrs: { name: "known-issues" } })],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [_c("page-snippet", { attrs: { name: "support-footer" } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }