<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <page-snippet name="support" />
      </b-col>

      <b-col md="6" cols="6" class="mt-4">
        <b-card header="Frequently Asked Questions">
          <page-snippet name="faq" />
        </b-card>
      </b-col>

      <b-col md="6" cols="12" class="mt-4">
        <b-card header="Known issues">
          <page-snippet name="known-issues" />
        </b-card>
      </b-col>

      <b-col cols="12">
        <page-snippet name="support-footer" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'Support',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    props: {
    },
    mounted () {
    }
  }
</script>